import '../App.css';
import End from "../Content/End";
import {AvorBuy} from "../Content/Content";
import Design from "../Content/Design";
import Header_2 from "../Background/Header_2";

const Link_3 = "/antivor/";
const Price_3 = ["2900"];


function BuyPage() {

    return (
        <div className="App">
            <header>
                <Header_2/>
            </header>

            <div id="Body">
            <div style={{paddingTop: '150px'}}>
                <AvorBuy
                    Design={Design}
                    TypeDesign={'3'}
                    Link={Link_3}
                    Price={Price_3}
                />
            </div>

            <End />
            </div>
        </div>
    );
}

export default BuyPage;
