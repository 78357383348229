import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainPage from './Pages/MainPage';
import AvorPage from './Pages/AvorPage';
import Antivor from './Pages/antivor';
import AvorInstructions from './Pages/AvorInstructions';
import CyberProPage from './Pages/CyberProPage';
import CyberProFAQ from './Pages/CyberProFAQ';
import CyberProSupport from './Pages/CyberProSupport';
import AntiVorAntiVirus from './Pages/AntiVorAntiVirus';
import AvorDownloadUnisafe from './Pages/AvorDownloadUnisafe';
import AvorDownloadUsafe from './Pages/AvorDownloadUsafe';
import AvorDownloadProtect from './Pages/AvorDownloadProtect';
import Cyber from './Pages/Cyber';
import CreatePage from "./Pages/CreatePage";
import ProtectionStation from "./Pages/ProtectionStationPage";
import BuyPage from "./Pages/BuyPage";
import ProtectionStationRedirect from "./RedirectPages/ProtectionStationRedirect";
import './i18n';
import AvorPageForMegafon from "./Pages/AvorPageForMegafon";
import CyberProPageForMegafon from "./Pages/CyberProPageForMegafon";
import LicenseAvorRedirect from "./RedirectPages/License-avor-redirect";
import SvazOn from "./SvazOn";


function App() {
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route path="/" element={<MainPage/>}/>
                    <Route path="/AntiThief" element={<AvorPage/>} />
                    <Route path="/antivor" element={<Antivor/>} />
                    <Route path="/AntiThiefDownload" element={<AvorPageForMegafon/>} />
                    <Route path="/buy/" element={<BuyPage/>} />
                    <Route path="/instructions/" element={<AvorInstructions/>} />
                    <Route path="/CyberProtection" element={<CyberProPage/>} />
                    <Route path="/CyberProtectionDownload" element={<CyberProPageForMegafon/>} />
                    <Route path="/CyberProtectionFAQ" element={<CyberProFAQ/>} />
                    <Route path="/CyberProtectionSupport" element={<CyberProSupport/>} />
                    <Route path="/AntiVorAntiVirus" element={<AntiVorAntiVirus/>}/>
                    <Route path="/AvorDownloadUnisafe" element={<AvorDownloadUnisafe/>}/>
                    <Route path="/AvorDownloadUsafe" element={<AvorDownloadUsafe/>}/>
                    <Route path="/AvorDownloadProtect" element={<AvorDownloadProtect/>}/>
                    <Route path="/Cyber" element={<Cyber/>}/>
                    <Route path="/PriceListSvazOn" element={<SvazOn/>}/>
                    <Route path="/ProtectionStation" element={<ProtectionStation/>}/>
                    <Route path="/services/protection-station/" element={<ProtectionStationRedirect/>} />
                    <Route path="/product" element={<CreatePage/>} />

                    {/*Редиректы для всякого рода помощи*/}
                    <Route path="/license" element={<LicenseAvorRedirect />}/>
                    <Route path="/license/" element={<LicenseAvorRedirect />}/>
                </Routes>
            </Router>
        </div>
    );
}

export default App;
